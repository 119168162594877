<template>
  <span :class="classes">
    <!-- @slot The hint text  -->
    <slot />
  </span>
</template>

<script lang="ts">
import { computed } from 'vue'

import { InputState, VueClass } from 'komodo2/types.ts'

export default {
  name: 'KomodoInputHint'
}
</script>

<script setup lang="ts">
interface Props {
  /**
   * Indicates what state the hint should be rendered in
   */
  state?: InputState
}

const props = withDefaults(defineProps<Props>(), {
  state: InputState.Default
})

const classes = computed((): VueClass => {
  return {
    'k2-input-hint': true,
    'k2-input-hint-error': props.state === InputState.Error
  }
})

</script>

<style lang="scss" scoped>
.k2-input-hint {
  @include k2-font-text-caption;
  @include k2-color-text-secondary-text;

  margin-left: 12px;

  &.k2-input-hint-error {
    @include k2-color-error-darken-1;
  }

}
</style>
